import React, { Component, Fragment } from "react";
import { Grid, Container } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import Card from "./components/card";
import { ToastContainer, toast } from "react-toastify";
import {
  send15CbData,
  send15CaData,
  send15CaCData,
} from "../../services/sendCsv";

const styles = {
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
};

class GenerateXml extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName15cb: "",
      fileName15ca: "",
      fileName15cbForPartC: "",
      select15cbFileForPartC: "",
      select15cbFile: null,
      download15cb: null,
      select15caFile: null,
      download15ca: null,
      error15cb: "",
      error15ca: "",
      part: "",
      loading: false,
    };
  }

  handle15cbChange = (event) => {
    this.setState({
      error15ca: null,
      error15cb: null,
      download15cb: null,
      select15cbFile: event.target.files,
      fileName15cb: event.target.files[0].name,
    });
  };

  handle15caChange = (event) => {
    this.setState({
      error15ca: null,
      error15cb: null,
      download15ca: null,
      select15caFile: event.target.files,
      fileName15ca: event.target.files[0].name,
    });
  };

  handle15cbForPartcChange = (event) => {
    this.setState({
      select15caFile: null,
      error15ca: null,
      error15cb: null,
      download15ca: null,
      select15cbFileForPartC: event.target.files,
      fileName15cbForPartC: event.target.files[0].name,
    });
  };

  handleSelectChange = ({ target }) => {
    const part = target.value;
    this.setState({
      part,
      select15cbFileForPartC: "",
      select15caFile: null,
      fileName15ca: "",
      fileName15cbForPartC: "",
      download15ca: null,
      error15ca: null,
    });
  };

  handle15cbSubmit = async () => {
    this.setState({ loading15cb: !this.state.loading15cb });
    try {
      const data = new FormData();
      data.append("documentType", "15CB");
      data.append("file", this.state.select15cbFile[0]);
      const result = await send15CbData(data);
      if (result.status === 201) {
        this.setState({
          download15cb: result.data.data.document.xml,
          loading15cb: !this.state.loading15cb,
        });
        toast.success("Download your 15CB XML files");
      }
    } catch (error) {
      const errorMsg = error.response.data.message;
      if (Array.isArray(errorMsg)) {
        this.setState({
          error15cb: errorMsg,
          loading15cb: !this.state.loading15cb,
        });
        toast.error("Validation Errors!");
      } else {
        this.setState({ loading15cb: !this.state.loading15cb });
        toast.error(error.response.data.message);
      }
    }
  };

  handle15caSubmit = async () => {
    this.setState({ loading15ca: !this.state.loading15ca });
    try {
      const data = new FormData();
      data.append("documentType", "15CA");
      data.append("part", this.state.part);
      data.append("file", this.state.select15caFile[0]);
      if (this.state.part === "C") {
        data.append("file15CB", this.state.select15cbFileForPartC[0]);
      }
      const result =
        this.state.part === "C"
          ? await send15CaCData(data)
          : await send15CaData(data);
      if (result.status === 201) {
        this.setState({
          download15ca: result.data.data.document.xml,
          loading15ca: !this.state.loading15ca,
        });
        toast.success("Download your 15CA XML files");
      }
    } catch (error) {
      const errorMsg = error.response.data.message;
      if (Array.isArray(errorMsg)) {
        this.setState({
          error15ca: errorMsg,
          loading15ca: !this.state.loading15ca,
        });
        toast.error("Validation Errors!");
      } else {
        this.setState({ loading15ca: !this.state.loading15ca });
        toast.error(error.response.data.message);
      }
    }
  };

  render() {
    const {
      error15cb,
      error15ca,
      download15cb,
      download15ca,
      fileName15ca,
      fileName15cb,
      loading15cb,
      loading15ca,
      fileName15cbForPartC,
      select15cbFile,
      select15caFile,
      select15cbFileForPartC,
      part,
    } = this.state;
    return (
      <Fragment>
        <ToastContainer autoClose={false} />
        <div>
          <main style={styles.content}>
            <Container maxWidth="lg">
              <br />
              <Grid
                container
                direction="row"
                spacing={5}
                justify="space-around"
              >
                <Grid item md={5} lg={5} sm={12} xs={12}>
                  <Card
                    heading="15CB"
                    variant="horizontal"
                    loading={loading15cb}
                    fileName15cb={fileName15cb}
                    download15cb={download15cb}
                    file15cbSelected={select15cbFile !== null ? true : false}
                    onFile15cbChange={this.handle15cbChange}
                    on15cbSubmit={this.handle15cbSubmit}
                  />
                  <Card
                    heading="15CA"
                    variant="horizontal"
                    part={part}
                    loading={loading15ca}
                    fileName15cbForPartC={fileName15cbForPartC}
                    file15cbForPartcSelected={select15cbFileForPartC}
                    onFile15cbForPartcChange={this.handle15cbForPartcChange}
                    fileName15ca={fileName15ca}
                    download15ca={download15ca}
                    file15caSelected={select15caFile !== null ? true : false}
                    onFile15caChange={this.handle15caChange}
                    on15caSubmit={this.handle15caSubmit}
                    onSelect={this.handleSelectChange}
                  />
                </Grid>
                <Grid item md={5} lg={5} xs={12} sm={12}>
                  <Card
                    heading="Validation Errors"
                    variant="vertical"
                    error15ca={error15ca}
                    error15cb={error15cb}
                  />
                </Grid>
              </Grid>
              <br />
            </Container>
          </main>
        </div>
      </Fragment>
    );
  }
}

export default GenerateXml;
