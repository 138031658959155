import { Button, CircularProgress } from "@material-ui/core";
import React, { Fragment } from "react";
import { CloudUpload, CloudDownload, Send } from "@material-ui/icons";

const styles = {
  general: {
    marginTop: 10,
    padding: 5,
    fontWeight: "bold",
    width: 145,
  },
  enabledButton: {
    backgroundColor: "#007bff",
    color: "white",
  },
  disabledButton: {
    backgroundColor: "#cccccc",
    color: "#666666",
  },
};

export const CustomButton = ({
  variant,
  label,
  disabled,
  onClick,
  loading,
}) => {
  return (
    <Fragment>
      <Button
        disabled={disabled}
        style={
          disabled
            ? Object.assign({}, styles.general, styles.disabledButton)
            : Object.assign({}, styles.general, styles.enabledButton)
        }
        component={variant === "upload" ? "span" : "div"}
        startIcon={
          variant === "upload" ? (
            <CloudUpload />
          ) : variant === "download" ? (
            <CloudDownload />
          ) : variant === "submit" ? (
            loading ? null : (
              <Send />
            )
          ) : null
        }
        size="medium"
        onClick={onClick}
      >
        {variant === "submit" ? (
          loading ? (
            <CircularProgress style={{ color: "white" }} size="1.8rem" />
          ) : (
            label
          )
        ) : (
          label
        )}
      </Button>
    </Fragment>
  );
};
