import React from "react";
import { TableCell, TableRow, IconButton } from "@material-ui/core";
import CustomTable from "../../../components/table/table";
import GetAppIcon from "@material-ui/icons/GetApp";

const getDate = (utcDate) => {
  var localDate = new Date(utcDate);
  return localDate.toString().split("GMT")[0];
};

const TransactionTable = ({
  tableHead,
  transactionList,
  handleSort,
  sortDirection,
  handlePageForwardChange,
  handlePageBackChange,
  pageNumber,
  pageCount,
}) => {
  const tbody = transactionList.map((item, i) => {
    return (
      <TableRow key={item._id}>
        <TableCell align="center" component="th" scope="row">
          {pageNumber * 10 - 10 + (i + 1)}
        </TableCell>
        <TableCell align="center">{item.doc.type.toUpperCase()}</TableCell>
        <TableCell align="center">
          {item.doc.type === "15cb" ? "" : item.doc.part.toUpperCase()}
        </TableCell>
        <TableCell align="center">{getDate(item.createdAt)}</TableCell>
        <TableCell align="center">
          <IconButton aria-label="download">
            <a href={item.csv}>
              <GetAppIcon />
            </a>
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <IconButton aria-label="download">
            <a href={item.xml}>
              <GetAppIcon />
            </a>
          </IconButton>
        </TableCell>
        <TableCell align="center" component="span">
          {item.count}
        </TableCell>
      </TableRow>
    );
  });
  return (
    <CustomTable
      tableHead={tableHead}
      tbody={tbody}
      handleSort={handleSort}
      sortDirection={sortDirection}
      handlePageForwardChange={handlePageForwardChange}
      handlePageBackChange={handlePageBackChange}
      pageNumber={pageNumber}
      pageCount={pageCount}
    />
  );
};
export default TransactionTable;
