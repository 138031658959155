import React, { Component, Fragment } from "react";
import {
  Typography,
  Container,
  withStyles,
  Grid,
  Paper,
} from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { transactionListForAdmin } from "../../components/table/tableHead";
import { getDocumentsOfUserById } from "../../services/getDocumentsOfUser";
import UserTransactionTable from "./components/userTransactionTable";

const styles = {
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: 32,
  },
  paperHeading: {
    color: "black",
    fontWeight: "bold",
    fontSize: 25,
  },
};

class UserTransaction extends Component {
  state = {
    userId: "",
    transactionList: [],
    sort: "-createdAt",
    page: 1,
    totalCount: "",
  };

  async componentDidMount() {
    const { sort, page } = this.state;
    try {
      const { id } = this.props.match.params;
      const { data: transaction } = await getDocumentsOfUserById(
        id,
        sort,
        page
      );
      this.setState({
        transactionList: transaction.data.docs,
        totalCount: transaction.totalCount,
        userId: id,
      });
    } catch (error) {
      toast.error("Internal server error");
    }
  }

  handleSort = async (e) => {
    const { sort } = this.state;
    const sortChange = sort === "+count" ? "-count" : "+count";
    this.setState({ sort: sortChange }, () => {
      this.changeSort(e);
    });
  };

  changeSort = async () => {
    const { userId, sort } = this.state;
    try {
      const { data: transaction } = await getDocumentsOfUserById(userId, sort);
      this.setState({ transactionList: transaction.data.docs });
    } catch (error) {
      toast.error("Internal server error");
    }
  };

  handlePageForwardChange = async (e) => {
    const { page } = this.state;
    const pageChange = page + 1;
    this.setState({ page: pageChange }, () => {
      this.changePage(e);
    });
  };

  handlePageBackChange = async (e) => {
    const { page } = this.state;
    const pageChange = page - 1;
    this.setState({ page: pageChange }, () => {
      this.changePage(e);
    });
  };

  changePage = async () => {
    const { userId, sort, page } = this.state;
    try {
      const { data: transaction } = await getDocumentsOfUserById(
        userId,
        sort,
        page
      );
      this.setState({ transactionList: transaction.data.docs });
    } catch (error) {
      toast.error("Internal server error");
    }
  };

  render() {
    const { classes } = this.props;
    const { transactionList, sort, page, totalCount } = this.state;
    const pageCount = Math.ceil(totalCount / 10);

    return (
      <Fragment>
        <ToastContainer autoClose={false} />
        <Grid>
          <main className={classes.content}>
            <Container maxWidth="lg">
              <br />
              <Paper className={classes.paper} elevation={2}>
                <Fragment>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.paperHeading}
                  >
                    Transaction List
                  </Typography>
                  <br />
                  <Typography component="h6" variant="h6">
                    Total: <b>{totalCount}</b>
                  </Typography>
                  <br />
                </Fragment>
                {transactionList.length > 0 ? (
                  <Fragment>
                    <UserTransactionTable
                      className={classes.table}
                      tableHead={transactionListForAdmin}
                      transactionList={transactionList}
                      handleSort={this.handleSort}
                      sortDirection={sort}
                      handlePageForwardChange={this.handlePageForwardChange}
                      handlePageBackChange={this.handlePageBackChange}
                      pageNumber={page}
                      pageCount={pageCount}
                    />
                  </Fragment>
                ) : null}
                <br />
              </Paper>
              <br />
            </Container>
          </main>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(UserTransaction);
