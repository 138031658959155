import http from "./httpServices";
import config from "../config";

const urlToGetUsers = config.apiUrl + "/v1/auth/user/";

export function getUsers() {
  const result = http.get(urlToGetUsers + "?sort=-dateCreated");
  return result;
}

export default {
  getUsers,
};
