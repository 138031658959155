import React, { Fragment } from "react";
import { Grid, Paper, withStyles, Typography } from "@material-ui/core";
import CustomCopyButton from "./customCopyButton";
import { CustomButton } from "./customButton";
import { SelectField } from "../../../components/select";

const styles = {
  horizontalCard: {
    height: "15vh",
  },
  paper: {
    padding: 15,
    marginBottom: "5vh",
  },
  verticalCard: {
    height: "75vh",
    overflow: "auto",
  },
  paperHeading: {
    color: "black",
    fontWeight: "bold",
    fontSize: 25,
  },
};

const selectPart = [
  {
    value: "A",
    label: "Part A",
  },
  {
    value: "C",
    label: "Part C",
  },
  {
    value: "D",
    label: "Part D",
  },
];

export const Card = ({
  heading,
  part,
  fileName15ca,
  fileName15cb,
  fileName15cbForPartC,
  error15ca,
  error15cb,
  variant,
  onFile15caChange,
  onFile15cbChange,
  onFile15cbForPartcChange,
  on15cbSubmit,
  on15caSubmit,
  loading,
  download15ca,
  download15cb,
  onSelect,
  file15cbSelected,
  file15caSelected,
  file15cbForPartcSelected,
}) => {
  return (
    <Fragment>
      <Paper style={styles.paper} elevation={2}>
        {variant === "horizontal" ? (
          <Fragment>
            <Typography style={styles.paperHeading}>{heading}</Typography>
            <br />
            {heading === "15CA" && (
              <Fragment>
                <SelectField
                  label="Select Part"
                  options={selectPart}
                  onChange={onSelect}
                />
                <br />
              </Fragment>
            )}
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="flex-end"
            >
              <Grid item>
                <div style={styles.horizontalCard}>
                  <Fragment>
                    <input
                      name={
                        heading === "15CA"
                          ? part === "C"
                            ? "select15cbFileForPartC"
                            : "select15caFile"
                          : "select15cbFile"
                      }
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      style={{ display: "none" }}
                      id={
                        heading === "15CA"
                          ? part === "C"
                            ? "15cbForPartC"
                            : "15ca"
                          : "15cb"
                      }
                      type="file"
                      onChange={
                        heading === "15CA"
                          ? part === "C"
                            ? onFile15cbForPartcChange
                            : onFile15caChange
                          : onFile15cbChange
                      }
                    />
                    <label
                      htmlFor={
                        heading === "15CA"
                          ? part === "C"
                            ? "15cbForPartC"
                            : "15ca"
                          : "15cb"
                      }
                    >
                      <CustomButton
                        label={part === "C" ? "Upload 15CB" : "Upload CSV"}
                        variant="upload"
                        disabled={
                          heading === "15CA"
                            ? part === ""
                              ? true
                              : false
                            : null
                        }
                      />
                      <p>
                        {heading === "15CA"
                          ? part === "C"
                            ? fileName15cbForPartC
                            : fileName15ca
                          : fileName15cb}
                      </p>
                    </label>
                  </Fragment>
                </div>
              </Grid>
              <Grid item>
                <div style={styles.horizontalCard}>
                  {heading === "15CA" && part === "C" ? (
                    <Fragment>
                      <input
                        name="select15caFile"
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        style={{ display: "none" }}
                        id="15ca"
                        type="file"
                        onChange={onFile15caChange}
                      />
                      <label htmlFor="15ca">
                        <CustomButton
                          label="Upload CSV"
                          variant="upload"
                          disabled={
                            file15cbForPartcSelected === "" ? true : false
                          }
                        />
                        <p>{fileName15ca}</p>
                      </label>
                    </Fragment>
                  ) : (
                    <CustomButton
                      label="Submit"
                      disabled={
                        heading === "15CA"
                          ? (file15caSelected && download15ca) === null
                            ? false
                            : true
                          : (file15cbSelected && download15cb) === null
                          ? false
                          : true
                      }
                      variant="submit"
                      loading={loading}
                      onClick={heading === "15CA" ? on15caSubmit : on15cbSubmit}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="flex-end"
            >
              {heading === "15CA" && part === "C" ? (
                <Grid item>
                  <CustomButton
                    label="Submit"
                    loading={loading}
                    disabled={
                      (file15caSelected && download15ca) === null ? false : true
                    }
                    variant="submit"
                    onClick={on15caSubmit}
                  />
                </Grid>
              ) : null}
              <Grid item>
                <a
                  style={{ textDecoration: "none" }}
                  href={heading === "15CA" ? download15ca : download15cb}
                  target="_blank"
                  download
                  rel="noopener noreferrer"
                >
                  <CustomButton
                    label="Download"
                    variant="download"
                    disabled={
                      heading === "15CA"
                        ? download15ca !== null
                          ? false
                          : true
                        : download15cb !== null
                        ? false
                        : true
                    }
                  />
                </a>
              </Grid>
            </Grid>
            <br />
          </Fragment>
        ) : (
          <div style={styles.verticalCard}>
            <Typography style={styles.paperHeading}>{heading}</Typography>
            <Fragment>
              <Typography variant="h6">
                Total number of Errors:{" "}
                {error15cb !== null
                  ? error15cb.length
                  : error15ca !== null
                  ? error15ca.length
                  : "0"}
              </Typography>
              <br />
              {Array.isArray(error15cb !== null ? error15cb : error15ca)
                ? (error15cb !== null ? error15cb : error15ca).map((err, i) => {
                    return (
                      <Fragment>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="flex-start"
                        >
                          <Typography style={{ color: "red" }}>
                            {i + 1}.&nbsp; Row: {err.path[0] + 1}
                          </Typography>
                          <CustomCopyButton copy={err.path[1]} />
                        </Grid>
                        <Typography style={{ color: "blue" }}>
                          Message: {err.message}
                        </Typography>
                        <br />
                      </Fragment>
                    );
                  })
                : null}
            </Fragment>
          </div>
        )}
      </Paper>
    </Fragment>
  );
};

export default withStyles(styles)(Card);
