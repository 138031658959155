import React, { Fragment } from "react";
import { Typography, withStyles, Container, Paper } from "@material-ui/core";
import SignupFields from "./components/signupFields";
import Form from "../../components/form/form";
import { createUser } from "../../services/createUser";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const styles = {
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: 32,
  },
  paperHeading: {
    color: "black",
    fontWeight: "bold",
    fontSize: 25,
  },
};

class AddUsers extends Form {
  state = {
    data: {},
  };

  onSubmit = async () => {
    const data = {
      ...this.state.data,
    };
    if (data["userType"] === "")
      return alert("Role cannot be empty. Please assign a role");
    else {
      try {
        const result = await createUser(data);
        if (result.status === 201) toast.success(result.data.message);
      } catch (error) {
        const { data } = error.response;
        toast.error(data.message);
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <ToastContainer autoClose={false} />
        <div>
          <main className={classes.content}>
            <Container maxWidth="lg">
              <br />
              <Paper className={classes.paper} elevation={2}>
                <div>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.paperHeading}
                  >
                    Register Users
                  </Typography>
                </div>
                <br />
                <div>
                  <SignupFields
                    onSubmit={this.handleSubmit}
                    onChange={this.handleOnChange}
                  />
                </div>
              </Paper>
              <br />
            </Container>
          </main>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(AddUsers);
