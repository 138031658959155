import http from "./httpServices";
import config from "../config";

const apiUrlToUserStatus = config.apiUrl + "/v1/auth/user/";

export function changeUserStatus(user) {
  const isActive = user.isActive;
  const result = http.put(apiUrlToUserStatus + user._id, { isActive });
  return result;
}
