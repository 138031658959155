import React, { Fragment } from "react";
import { withStyles, Typography } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableFooter,
  TableHead,
  IconButton,
} from "@material-ui/core";
import {
  ChevronLeft,
  ChevronRight,
} from "@material-ui/icons";

const styles = {
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: 650,
    maxWidth: 1200,
  },
  tableHeader: {
    fontWeight: "bold",
    fontSize: 18,
    minWidth: 100,
  },
  tableBody: {
    fontSize: 15000,
  },
};

const CustomTable = ({
  classes,
  tableHead,
  tbody,
  handleSort,
  sortDirection,
  handlePageForwardChange,
  handlePageBackChange,
  pageNumber,
  pageCount,
}) => {
  // const renderCount = () => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //       }}
  //       onClick={handleSort}
  //     >
  //       {sortDirection === "+count" ? (
  //         <ArrowDropUp fontSize="large" />
  //       ) : (
  //         <ArrowDropDown fontSize="large" />
  //       )}
  //       Count
  //     </div>
  //   );
  // };

  return (
    <Fragment>
      <TableContainer className={classes.container} component={Paper}>
        <Table
          stickyHeader
          aria-label="sticky table"
          className={classes.table}
          size="small"
        >
          <TableHead>
            <TableRow>
              {tableHead.map(function (item) {
                return (
                  <TableCell align="center" className={classes.tableHeader}>
                    {item.value}
                    {/* {item.value === "Count" ? null : item.value}
                    {item.value === "Count" ? renderCount() : null} */}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>{tbody}</TableBody>
        </Table>
      </TableContainer>
      {pageNumber && pageCount ? (
        <TableFooter align="center">
          <br />
          <Typography>
            Current Page: <b>{pageNumber}</b>
          </Typography>
          <IconButton
            aria-label="pageBack"
            color="primary"
            disabled={pageNumber === 1 ? true : false}
            onClick={handlePageBackChange}
          >
            <Typography>Previous</Typography>
            <ChevronLeft fontSize="large" />
          </IconButton>
          <IconButton
            aria-label="pageForward"
            color="primary"
            disabled={pageNumber === pageCount ? true : false}
            onClick={handlePageForwardChange}
          >
            <ChevronRight fontSize="large" />
            <Typography>Next</Typography>
          </IconButton>
        </TableFooter>
      ) : null}
    </Fragment>
  );
};
export default withStyles(styles)(CustomTable);
