import React from "react";
import { TableCell, TableRow } from "@material-ui/core";
import CustomTable from "../../../components/table/table";
import { Link } from "react-router-dom";
import Switch from "../../../components/switch";

const UserListTable = ({ tableHead, userList, handleChange }) => {
  const tbody = userList.map((item, i) => {
    return (
      <TableRow key={item._id}>
        <TableCell align="center" component="th" scope="row">
          {i + 1}
        </TableCell>
        <TableCell
          style={{ textDecoration: "none" }}
          align="center"
          scope="row"
          component={Link}
          to={`/dashboard/users/${item._id}`}
        >
          {item.name.firstName} {item.name.lastName}
        </TableCell>
        <TableCell align="center">
          <a href={"mailto:" + item.email} style={{ textDecoration: "none" }}>
            {item.email}
          </a>
        </TableCell>
        <TableCell align="center">{item.contact}</TableCell>
        <TableCell align="center">{item.dateCreated.split("T")[0]}</TableCell>
        <TableCell align="center">{item.cb_15.length}</TableCell>
        <TableCell align="center">{item.ca_15.length}</TableCell>
        <TableCell align="center">
          <Switch
            onChangeHandler={() => handleChange(item)}
            checked={item.isActive}
          />
        </TableCell>
      </TableRow>
    );
  });
  return <CustomTable tableHead={tableHead} tbody={tbody} />;
};
export default UserListTable;
