import React, { Component, Fragment } from "react";
import {
  Typography,
  Container,
  withStyles,
  Grid,
  Paper,
} from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { getUsers } from "../../services/getUsers";
import { userListHead } from "../../components/table/tableHead";
import UserListTable from "./components/userListTable";
import { changeUserStatus } from "../../services/userStatusChange";

const styles = {
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: 32,
  },
  paperHeading: {
    color: "black",
    fontWeight: "bold",
    fontSize: 25,
  },
};

class UsersList extends Component {
  state = { userList: [] };

  async componentDidMount() {
    try {
      const { data: userList } = await getUsers();
      this.setState({ userList: userList.data.users });
    } catch (error) {
      toast.error("Internal server error");
    }
  }

  handleSwitchChange = async (e) => {
    const { userList } = this.state;
    const index = userList.findIndex((user) => user._id === e._id);
    userList[index].isActive = !userList[index].isActive;
    this.setState({ userList }, () => {
      this.changeUserStatus(e);
    });
  };

  changeUserStatus = async (user) => {
    try {
      const { data } = await changeUserStatus(user);
      toast.success(data.message);
    } catch (error) {
      toast.error("Failed to change user status");
    }
  };

  render() {
    const { classes } = this.props;
    const { userList } = this.state;

    return (
      <Fragment>
        <ToastContainer autoClose={false} />
        <Grid>
          <main className={classes.content}>
            <Container maxWidth="lg">
              <br />
              <Paper className={classes.paper} elevation={2}>
                <div>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.paperHeading}
                  >
                    Users list
                  </Typography>
                  <br />
                  <Typography component="h6" variant="h6">
                    Total: <b>{userList.length}</b>
                  </Typography>
                  <br />
                </div>
                {userList.length > 0 ? (
                  <Fragment>
                    <UserListTable
                      tableHead={userListHead}
                      userList={userList}
                      handleChange={this.handleSwitchChange}
                    />
                  </Fragment>
                ) : null}
                <br />
              </Paper>
              <br />
            </Container>
          </main>
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(UsersList);
