import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import DashboardLayout from "../../components/dashboard/dashboardLayout";
import GenerateXml from "./genrateXml";
import TransactionList from "./transactionList";
import AddCredits from "./addCredits";
import PageNotFound from "../../components/pageNotFound";

class AdminDashboard extends Component {
  state = {};
  render() {
    const { user } = this.props;
    return (
      <DashboardLayout user={user}>
        <Switch>
          <Route exact path="/dashboard/" component={GenerateXml} />
          <Route
            path="/dashboard/transactionList"
            render={(props) => <TransactionList user={user} {...props} />}
          />
          <Route
            path="/dashboard/addCredits"
            render={(props) => <AddCredits user={user} {...props} />}
          />
          <Route component={PageNotFound} />
        </Switch>
      </DashboardLayout>
    );
  }
}

export default AdminDashboard;
