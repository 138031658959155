import React, { Component } from "react";
import { Grid, Button } from "@material-ui/core";
import InputField from "../../../components/form/inputField";
import { SelectField } from "../../../components/select";

class SignupFields extends Component {
  state = {};

  render() {
    const userType = [
      {
        value: "client",
        label: "Client",
      },
      {
        value: "root",
        label: "Admin",
      },
    ];
    const { onChange, onSubmit } = this.props;
    return (
      <form onSubmit={onSubmit}>
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <InputField
                required
                id="firstname"
                label="First Name"
                name="firstName"
                autoFocus
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <InputField
                required
                id="lastName"
                label="Last Name"
                name="lastName"
                autoFocus
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <InputField
                required
                id="email"
                label="Email"
                type="email"
                name="email"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <InputField
                required
                id="company"
                label="Company Name"
                name="company"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <InputField
                required
                id="contact"
                label="Contact"
                name="contact"
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={6} md={4} lg={4} className="select-button-padding">
              <SelectField
                onChange={onChange}
                options={userType}
                label="User Type"
                name="userType"
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <div>
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#007bff", color: "white" }}
                  type="submit"
                >
                  Register User
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }
}

export default SignupFields;
