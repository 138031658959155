export let userListHead = [
  {
    value: "Sr.No",
  },
  {
    value: "Name",
  },
  {
    value: "Email",
  },
  {
    value: "Contact",
  },
  {
    value: "Date Registered",
  },
  {
    value: "15CB",
  },
  {
    value: "15CA",
  },
  {
    value: "Status",
  },
];

export let transactionListForClient = [
  {
    value: "Sr.No",
  },
  {
    value: "Document Type",
  },
  {
    value: "Part",
  },
  {
    value: "Date Created",
  },
  {
    value: "CSV",
  },
  {
    value: "XML",
  },
  {
    value: "Count",
  },
];

export let transactionListForAdmin = [
  {
    value: "Sr.No",
  },
  {
    value: "Document Type",
  },
  {
    value: "Part",
  },
  {
    value: "Date Created",
  },
  {
    value: "Count",
  },
];
