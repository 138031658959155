const dev = {
  apiUrl: "http://localhost:5000",
};

const prod = {
  apiUrl: "https://cacb15-xml-converter-bulk.onrender.com",
};

const config = process.env.NODE_ENV === "production" ? prod : dev;
// const config = prod; // Enable while running in docker

export default {
  ...config,
};
