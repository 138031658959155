import config from "../config";
import http from "./httpServices";

const apiUrlTosend15cb = config.apiUrl + "/v1/api/convert/";
const apiUrlTosend15ca = config.apiUrl + "/v1/api/convert/15CA";
const apiUrlTosend15cac = config.apiUrl + "/v1/api/convert/15CAC";

export function send15CbData(data) {
  const result = http.post(apiUrlTosend15cb, data);
  return result;
}

export function send15CaData(data) {
  const result = http.post(apiUrlTosend15ca, data);
  return result;
}

export function send15CaCData(data) {
  const result = http.post(apiUrlTosend15cac, data);
  return result;
}

export default {
  send15CbData,
  send15CaData,
  send15CaCData,
};
