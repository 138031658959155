import http from "./httpServices";
import config from "../config";

const apiToCreateUser = config.apiUrl + "/v1/auth/user/signup";

export async function createUser({
  firstName,
  lastName,
  email,
  contact,
  company,
  userType,
}) {
  const name = {
    firstName: firstName,
    lastName: lastName,
  };
  const result = http.post(apiToCreateUser, {
    name,
    email,
    contact,
    company,
    userType,
  });
  return result;
}
