import React, { Fragment } from "react";
import Form from "../../components/form/form";
import InputField from "../../components/form/inputField";
import {
  Grid,
  Button,
  Container,
  Paper,
  Typography,
  withStyles,
} from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { getPaymentData } from "../../services/payment";

const styles = {
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: 32,
  },
  paperHeading: {
    color: "black",
    fontWeight: "bold",
    fontSize: 25,
  },
};

function loadRazorpay(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    document.body.appendChild(script);
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
  });
}

class AddCredits extends Form {
  state = { data: {}, user: {}, error: "" };

  componentDidMount() {
    const { user } = this.props;
    this.setState({ user });
  }

  displayRazorpay = async () => {
    const { user, data } = this.state;
    if (data.noOfXml >= 1) {
      this.setState({ error: "" });
      const response = await loadRazorpay(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!response) {
        alert("Couldnt load Razorpay");
        return;
      }

      const { data: orderData } = await getPaymentData(data.noOfXml);

      const options = {
        key: "",
        amount: orderData.order.amount,
        order_id: orderData.order.id,
        currency: orderData.order.currency,
        name: "15CACB XML Generator | Flookup",
        description: "Test Transaction",
        handler: function (response) {
          toast.success("Payment SuccessFull !");
        },
        prefill: {
          email: user.email,
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else this.setState({ error: "Please enter valid number of XML" });
  };

  render() {
    const { classes } = this.props;
    const { data, error } = this.state;

    const amountToPay = data.noOfXml * 10;

    return (
      <Fragment>
        <ToastContainer autoClose={false} />
        <div>
          <main className={classes.content}>
            <Container maxWidth="lg">
              <br />
              <Paper className={classes.paper} elevation={2}>
                <div>
                  <Typography
                    component="h5"
                    variant="h5"
                    className={classes.paperHeading}
                  >
                    Add Credits
                  </Typography>
                </div>
                <br />
                <Fragment>
                  <Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <InputField
                        required
                        id="noOfXml"
                        label="Enter the number of XML's you want to generate"
                        type="number"
                        name="noOfXml"
                        onChange={this.handleOnChange}
                        error={error}
                        size="small"
                        margin="normal"
                      />
                      <br />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#007bff", color: "white" }}
                        onClick={this.displayRazorpay}
                      >
                        Proceed to pay &#8377;
                        {amountToPay ? amountToPay : null}
                      </Button>
                    </Grid>
                  </Grid>
                </Fragment>
              </Paper>
              <br />
            </Container>
          </main>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(AddCredits);
