import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import DashboardLayout from "../../components/dashboard/dashboardLayout";
import AddUsers from "./addUsers";
import UserList from "./userList";
import UserTransactions from "./userTransactions";
import PageNotFound from "../../components/pageNotFound";

class AdminDashboard extends Component {
  state = {};
  render() {
    const { user } = this.props;
    return (
      <DashboardLayout user={user}>
        <Switch>
          <Route exact path="/dashboard/" component={AddUsers} />
          <Route exact path="/dashboard/users" component={UserList} />
          <Route path="/dashboard/users/:id" component={UserTransactions} />
          <Route component={PageNotFound} />
        </Switch>
      </DashboardLayout>
    );
  }
}

export default AdminDashboard;
