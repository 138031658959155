import React, { Fragment } from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import copy from "copy-to-clipboard";

export default function CustomCopyButton(columnName) {
  const [open, setOpen] = React.useState(false);

  const copyToClipBoard = async (text) => {
    copy(text.copy);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Fragment>
      <Tooltip
        onClose={handleTooltipClose}
        title={open ? "Column copied" : "Click to copy column"}
      >
        <IconButton
          size="small"
          onClick={function (event) {
            handleTooltipOpen();
            copyToClipBoard(columnName);
          }}
        >
          <FileCopyOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}
