import React, { Component, Fragment } from "react";
import "./App.css";
import {
  Route,
  BrowserRouter as Router,
  Redirect,
  Switch,
} from "react-router-dom";
import { getCurrentUser } from "./services/auth";
import Login from "./screens/login";
import Logout from "./screens/logout";
import ForgotPassword from "./screens/forgotPassword";
import ClientDashboard from "./screens/client/clientDashboard";
import AdminDashboard from "./screens/admin/adminDashboard";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      role: "",
    };
  }

  componentDidMount() {
    try {
      const user = getCurrentUser();
      if (user) this.setState({ user, role: user.userType });
    } catch (error) {
      return null;
    }
  }

  render() {
    const user = this.state;
    return (
      <Fragment>
        <Router>
          <Switch>
            <Route path="/logout" component={Logout} />
            <Route exact path="/" component={Login} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route
              path="/dashboard/"
              render={(props) => {
                if (!user) return <Redirect exact to="/" />;
                if (user.role === "root")
                  return (
                    <AdminDashboard
                      roleValue={this.state.user.role}
                      user={this.state.user}
                      {...props}
                    />
                  );
                if (user.role === "client")
                  return (
                    <ClientDashboard
                      roleValue={this.state.user.role}
                      user={this.state.user}
                      {...props}
                    />
                  );
              }}
            />
          </Switch>
        </Router>
      </Fragment>
    );
  }
}

export default App;
