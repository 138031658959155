import http from "./httpServices";
import { getToken } from "./getToken";
import config from "../config";
import jwtDecode from "jwt-decode";

const authApiEndpoint = config.apiUrl + "/v1/auth/user/login";
const token = "token";
const userDetails = "User";

export async function login(email, password) {
  const data = await http.post(authApiEndpoint, { email, password });
  const xAuth = data.headers["x-auth-token"];
  const bearerToken = "Bearer " + xAuth;
  localStorage.setItem(token, bearerToken);
  http.setToken(xAuth);
  const user = JSON.stringify(jwtDecode(xAuth));
  localStorage.setItem(userDetails, user);
  return data;
}

export function logoutUser() {
  localStorage.removeItem(token);
  localStorage.removeItem(userDetails);
}

export function getCurrentUser() {
  try {
    const jwt = getToken();
    if (jwt) {
      return jwtDecode(jwt);
    } else {
      return null;
    }
  } catch (ex) {
    return null;
  }
}
