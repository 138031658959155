import React, { Fragment } from "react";
import { login } from "../services/auth";
import { Grid, Typography, Button } from "@material-ui/core";
import Form from "../components/form/form";
import InputField from "../components/form/inputField";

const styles = {
  tagline: {
    color: "white",
    fontSize: 30,
    fontWeight: "bold",
  },
  sideBarText: {
    paddingLeft: 80,
    paddingTop: 105,
  },
  projectName: {
    fontSize: 32,
    color: "white",
  },
  formSection: {
    padding: 20,
  },
  forgotPasswordLink: {
    textDecoration: "none",
    color: "#007bff",
    fontWeight: "bold",
  },
  loginButton: {
    backgroundColor: "#007bff",
    color: "white",
    width: 120,
    fontWeight: "bold",
  },
};

class Login extends Form {
  state = { data: {}, error: "" };

  onSubmit = async () => {
    const { data } = this.state;
    try {
      const result = await login(data.email, data.password);
      if (result.status === 200) {
        window.location = "/dashboard/";
      }
    } catch (ex) {
      console.log(ex.response.data.message);
      console.log(ex.response.data);
      this.setState({ error: ex.response.data.message });
      return null;
    }
  };

  render() {
    return (
      <Fragment>
        <Grid container direction="row">
          <Grid item lg={5}>
            <div className="loginPageSidebar">
              <div style={styles.sideBarText}>
                <Typography style={styles.projectName}>
                  Bulk 15CA & 15CB Online Generating Utility
                </Typography>
              </div>
            </div>
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            xs={12}
            lg={7}
            md={12}
          >
            <div style={styles.formSection}>
              <Typography variant="h4">
                <b>Sign in to your account</b>
              </Typography>
              <br />
              <br />
              <form onSubmit={this.handleSubmit}>
                <label>
                  <b>Email Address</b>
                </label>
                <InputField
                  required
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  autoComplete="email"
                  autoFocus={true}
                  onChange={this.handleOnChange}
                  type="email"
                  size="small"
                  margin="normal"
                />
                <br />
                <label>
                  <b>Password</b>
                </label>
                <InputField
                  required
                  name="password"
                  placeholder="Enter your password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={this.handleOnChange}
                  size="small"
                  margin="normal"
                  error={this.state.error}
                />
                <br />
                <Grid container direction="row" justify="space-between">
                  <Fragment>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        style={styles.loginButton}
                      >
                        Log In
                      </Button>
                    </Grid>
                    {/* <Grid item>
                      <Link
                        style={styles.forgotPasswordLink}
                        to={"/forgotpassword"}
                      >
                        Forgot password?
                      </Link>
                    </Grid> */}
                  </Fragment>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default Login;
