import http from "./httpServices";
import config from "../config";

const urlToGetDocumentsOfUsers = config.apiUrl + "/v1/auth/user/";

export function getDocumentsOfUserById(id, sort, page) {
  const result = http.get(
    urlToGetDocumentsOfUsers + id + "?sort=" + sort + "&page=" + page
  );
  return result;
}

export default {
  getDocumentsOfUserById,
};
