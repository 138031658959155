import React, { Fragment } from "react";
import { Grid, Typography, Button, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBackIosSharpIcon from "@material-ui/icons/ArrowBackIosSharp";
import Form from "../components/form/form";
import InputField from "../components/form/inputField";
// import { forgotPassword } from "services/auth/forgotPassword";

const styles = {
  tagline: {
    color: "white",
    fontSize: 30,
    fontWeight: "bold",
  },
  sideBarText: {
    padding: 75,
  },
  projectName: {
    fontSize: 32,
    color: "white",
  },
  formSection: {
    padding: 20,
    marginTop: 80,
  },
  backButton: {
    border: "1px solid grey",
    marginTop: 25,
  },
  backButtonLink: {
    textDecoration: "none",
    color: "#007bff",
    fontWeight: "bold",
  },
  submitButton: {
    backgroundColor: "#007bff",
    color: "white",
    marginTop: 8,
    fontWeight: "bold",
    width: 150,
  },
};

class ForgotPassword extends Form {
  state = {
    data: {},
    message: "",
  };

  onSubmit = () => {
    // const { email } = this.state.data;
    // const { data } = await forgotPassword(email);
    // this.setState({ message: data.msg });
    const { data } = this.state;
    console.log(data);
  };

  render() {
    const { message } = this.state;
    return (
      <Fragment>
        <Grid container direction="row">
          <Grid item lg={5}>
            <div className="loginPageSidebar">
              <div style={styles.sideBarText}>
                <Typography style={styles.projectName}>Project Name</Typography>
                <br />
                <Typography style={styles.tagline}>TAGLINE</Typography>
              </div>
            </div>
          </Grid>
          <Grid container justify="center" item xs={12} lg={7} md={12}>
            <Link style={styles.backButtonLink} to={"/"}>
              <IconButton aria-label="back" style={styles.backButton}>
                <ArrowBackIosSharpIcon />
              </IconButton>
            </Link>
            <div style={styles.formSection}>
              <Typography variant="h4" align="center">
                <b>Trouble logging in ?</b>
              </Typography>
              <br />
              <Typography>
                Enter your email and we'll send you a mail to reset your
                password
              </Typography>
              <br />
              {message && (
                <Fragment>
                  <br />
                  <Grid container alignItems="center" justify="center">
                    <p style={{ color: "#007bff" }}>{message}</p>
                  </Grid>
                </Fragment>
              )}
              <br />
              {!message && (
                <form onSubmit={this.handleSubmit}>
                  <Grid container alignItems="center" justify="center">
                    <Grid item xs={12} md={10} lg={10}>
                      <label>
                        <b>Email Address</b>
                      </label>
                      <InputField
                        required
                        id="email"
                        name="email"
                        placeholder="Enter your email address"
                        autoComplete="email"
                        autoFocus={true}
                        onChange={this.handleOnChange}
                        type="email"
                        size="small"
                        margin="normal"
                      />
                      <br />
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      style={styles.submitButton}
                    >
                      Submit
                    </Button>
                  </Grid>
                </form>
              )}
            </div>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default ForgotPassword;
